import React from 'react';
import { Link } from 'react-router-dom';
import './Navbar.css';  // Make sure this line is present

const Navbar = ({ onResetToChooseDrone }) => {
  return (
    <nav className="navbar">
      <div className="navbar-content">
        <Link to="/" className="logo">droneforge.</Link>
        <ul className="nav-links">
          <li><Link to="/build" onClick={onResetToChooseDrone}>Build</Link></li>
          <li><Link to="/about">About</Link></li>
          <li><Link to="/contact">Contact</Link></li>
        </ul>
      </div>
    </nav>
  );
};

export default Navbar;