"use client"
import React, { useEffect, useState, useCallback, useMemo } from "react";
import { FaRocket, FaPuzzlePiece, FaTools, FaChevronDown } from 'react-icons/fa';
import CheckoutButton from './StripeCheckout';
import { motion, AnimatePresence } from 'framer-motion';
import { submitWaitlist } from '../api/api';

const WaitlistForm = React.memo(() => {
  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    email: '',
    interest: '',
  });

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
    const { name, value } = e.target;
    setFormData(prev => ({ ...prev, [name]: value }));
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    try {
      const result = await submitWaitlist(formData);
      alert('Thank you for joining our waitlist!');
      setFormData({ firstName: '', lastName: '', email: '', interest: '' });
    } catch (error) {
      alert('Error submitting form. Please try again.');
    }
  };

  return (
    <motion.div
      className="bg-zinc-900 p-8 rounded-lg shadow-lg max-w-md w-full"
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.5 }}
    >
      <h3 className="text-xl font-bold mb-6 text-green-500 text-center font-mono">join the waitlist for batch 2</h3>
      <form onSubmit={handleSubmit} className="space-y-4">
        <motion.div
          className="relative"
          initial={{ opacity: 0, x: -20 }}
          animate={{ opacity: 1, x: 0 }}
          transition={{ delay: 0.1 }}
        >
          <div className="flex space-x-4">
            <input
              type="text"
              name="firstName"
              value={formData.firstName}
              onChange={handleInputChange}
              placeholder="first name"
              className="w-1/2 p-2 bg-transparent border-b border-green-500 focus:outline-none focus:border-green-300 transition-colors"
              required
            />
            <input
              type="text"
              name="lastName"
              value={formData.lastName}
              onChange={handleInputChange}
              placeholder="last name"
              className="w-1/2 p-2 bg-transparent border-b border-green-500 focus:outline-none focus:border-green-300 transition-colors"
              required
            />
          </div>
        </motion.div>
        <motion.div
          className="relative"
          initial={{ opacity: 0, x: -20 }}
          animate={{ opacity: 1, x: 0 }}
          transition={{ delay: 0.3 }}
        >
          <input
            type="email"
            name="email"
            value={formData.email}
            onChange={handleInputChange}
            placeholder="email"
            className="w-full p-2 bg-transparent border-b border-green-500 focus:outline-none focus:border-green-300 transition-colors"
            required
          />
        </motion.div>
        <motion.div
          className="relative"
          initial={{ opacity: 0, x: -20 }}
          animate={{ opacity: 1, x: 0 }}
          transition={{ delay: 0.3 }}
        >
          <select
            name="interest"
            value={formData.interest}
            onChange={handleInputChange}
            className="w-full p-2 bg-transparent border-b border-green-500 focus:outline-none focus:border-green-300 transition-colors text-black"
            required
            style={{ color: formData.interest ? 'black' : 'gray' }}
          >
            <option value="" disabled style={{ color: 'gray' }}>Select your interest</option>
            <option value="educator">Educator</option>
            <option value="developer">Developer</option>
            <option value="commercial">Commercial</option>
            <option value="other">Other</option>
          </select>
        </motion.div>
        <motion.button
          type="submit"
          className="w-full bg-green-500 text-black font-bold py-2 px-4 rounded-full hover:bg-green-400 transition-colors"
          whileHover={{ scale: 1.05 }}
          whileTap={{ scale: 0.95 }}
        >
          Join Waitlist
        </motion.button>
      </form>
    </motion.div>
  );
});

const Landing_Bryce: React.FC = () => {
  const [timeLeft, setTimeLeft] = useState("");
  const [videoPosition, setVideoPosition] = useState({ x: 0, y: 0, visible: false });
  const [showWaitlistModal, setShowWaitlistModal] = useState(false);

  const updateCountdown = useCallback(() => {
    const targetDate = new Date("September 27, 2024 00:00:00").getTime();
    const now = new Date().getTime();
    const distance = targetDate - now;

    if (distance < 0) {
      setTimeLeft("Countdown ended");
      return;
    }

    const days = Math.floor(distance / (1000 * 60 * 60 * 24));
    const hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
    const minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
    const seconds = Math.floor((distance % (1000 * 60)) / 1000);

    setTimeLeft(`${days}d ${hours}h ${minutes}m ${seconds}s`);
  }, []);

  useEffect(() => {
    const interval = setInterval(updateCountdown, 1000);
    return () => clearInterval(interval);
  }, [updateCountdown]);

  const handleMouseMove = (e: React.MouseEvent<HTMLDivElement>) => {
    const rect = e.currentTarget.getBoundingClientRect();
    const x = e.clientX - rect.left;
    const y = e.clientY - rect.top;

    setVideoPosition({ x, y, visible: x >= rect.width / 3 });
  };

  const handleMouseEnter = () => {
    setVideoPosition((prev) => ({ ...prev, visible: true }));
  };

  const handleMouseLeave = () => {
    setVideoPosition((prev) => ({ ...prev, visible: false }));
  };

  const memoizedWaitlistForm = useMemo(() => <WaitlistForm />, []);

  const handleJoinWaitlist = () => {
    setShowWaitlistModal(true);
  };

  const handleJoinDiscord = () => {
    window.open('https://discord.gg/xFh3xxfyUh', '_blank');
  };

  const closeWaitlistModal = () => {
    setShowWaitlistModal(false);
  };

  return (
    <div className="relative flex flex-col items-center justify-center min-h-screen p-4 pb-20 space-y-8 sm:space-y-16 sm:p-16 font-mono bg-black text-zinc-100 overflow-hidden">
      {/* Green circular gradient */}
      <div 
        className="absolute left-1/2 top-1/2 transform -translate-x-1/2 -translate-y-1/2 w-[1200px] h-[1200px] rounded-full opacity-30 blur-2xl"
        style={{
          background: 'radial-gradient(circle, rgba(16,185,129,0.6) 0%, rgba(16,185,129,0) 70%)',
          zIndex: 0
        }}
      />

      {/* Main content */}
      <main className="relative flex flex-col sm:flex-row gap-4 sm:gap-8 items-center justify-center w-full min-h-[65vh] z-10">
        <motion.div 
          className="flex flex-col gap-8 items-center sm:items-start order-2 sm:order-1"
          initial={{ opacity: 0, y: 50 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.8 }}
        >
          <motion.h1 
            className="text-4xl sm:text-6xl md:text-6xl lg:text-6xl xl:text-8xl 2xl:text-12xl font-bold bg-gradient-to-br from-white to-gray-800 text-transparent bg-clip-text"
            initial={{ scale: 0.9 }}
            animate={{ scale: 1 }}
            transition={{ duration: 0.5, type: "spring", stiffness: 120 }}
          >
            DRONEFORGE
          </motion.h1>
          <motion.p 
            className="text-lg sm:text-xl text-center sm:text-left font-mono"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ delay: 0.3, duration: 0.5 }}
          >
            1. the future of drone technology at your fingertips
          </motion.p>
          <motion.div 
            className="flex flex-col sm:flex-row items-center items-start gap-2"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ delay: 0.6, duration: 0.5 }}
          >
            <p className="text-lg sm:text-xl text-zinc-100 bg-gradient-to-br from-white to-gray-400 text-transparent bg-clip-text font-mono">2. preorders available now until</p>
            <motion.code 
              className="bg-black/[.05] dark:bg-white/[.06] py-1 px-4 rounded font-semibold text-green-500 text-lg sm:text-xl"
              animate={{ scale: [1, 1.05, 1] }}
              transition={{ repeat: Infinity, duration: 2 }}
            >
              {timeLeft}
            </motion.code>
          </motion.div>
          <motion.div className="flex flex-col sm:flex-row gap-4 items-center">
            <motion.div
              className="relative"
              whileHover={{ scale: 1.05 }}
              whileTap={{ scale: 0.95 }}
            >
              <motion.div
                className="absolute -inset-0.5 bg-green-500 opacity-75 rounded-full blur-sm"
                animate={{
                  scale: [1, 1.2, 1],
                  opacity: [0.7, 0.9, 0.7],
                }}
                transition={{
                  duration: 2,
                  repeat: Infinity,
                  ease: "easeInOut",
                }}
              />
              <motion.button
                onClick={handleJoinWaitlist}
                className="relative bg-black text-green-500 font-bold py-2 px-6 rounded-full border border-green-500 transition-colors duration-300 ease-in-out z-10 group"
              >
                <span className="relative z-10 group-hover:text-black transition-colors duration-300">Join Waitlist for Batch 2</span>
                <motion.div
                  className="absolute inset-0 bg-green-500"
                  initial={{ scaleX: 0 }}
                  whileHover={{ scaleX: 1 }}
                  transition={{ duration: 0.3 }}
                  style={{ originX: 0 }}
                />
              </motion.button>
            </motion.div>
            
            <motion.div
              className="relative ml-4 sm:ml-8"
              whileHover={{ scale: 1.05 }}
              whileTap={{ scale: 0.95 }}
            >
              <motion.div
                className="absolute -inset-0.5 bg-[#5865F2] opacity-75 rounded-full blur-sm"
                animate={{
                  scale: [1, 1.2, 1],
                  opacity: [0.7, 0.9, 0.7],
                }}
                transition={{
                  duration: 2,
                  repeat: Infinity,
                  ease: "easeInOut",
                }}
              />
              <motion.button
                onClick={handleJoinDiscord}
                className="relative bg-black text-[#5865F2] font-bold py-2 px-6 rounded-full border border-[#5865F2] transition-colors duration-300 ease-in-out z-10 group"
              >
                <span className="relative z-10 group-hover:text-black transition-colors duration-300">Join Discord</span>
                <motion.div
                  className="absolute inset-0 bg-[#6A75F3]"
                  initial={{ scaleX: 0 }}
                  whileHover={{ scaleX: 1 }}
                  transition={{ duration: 0.3 }}
                  style={{ originX: 0 }}
                />
              </motion.button>
            </motion.div>
          </motion.div>
        </motion.div>
        <motion.div
          className="relative order-1 sm:order-2 flex justify-center"
          initial={{ opacity: 0, x: 50 }}
          animate={{ opacity: 1, x: 0 }}
          transition={{ duration: 0.8 }}
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
          onMouseMove={handleMouseMove}
        >
          <motion.img
            src="/drone.png"
            alt="Drone Image"
            width={500}
            height={500}
            whileHover={{ rotate: 5 }}
            transition={{ type: "spring", stiffness: 300 }}
          />
          {videoPosition.visible && (
            <motion.div
              className="absolute flex items-center justify-center bg-black bg-opacity-75 rounded-lg"
              style={{
                left: videoPosition.x - 250,
                top: videoPosition.y - 250,
                width: 500,
                height: 500,
              }}
              initial={{ opacity: 0, scale: 0.8 }}
              animate={{ opacity: 1, scale: 1 }}
              exit={{ opacity: 0, scale: 0.8 }}
              transition={{ duration: 0.3 }}
            >
              <video src="/Demo.MOV" autoPlay muted className="w-full h-full object-cover rounded-lg" />
            </motion.div>
          )}
        </motion.div>
      </main>

      {/* Add the animated arrow here */}
      <motion.div
        className="flex justify-center mt-2 sm:mt-4 -mb-10 sm:-mb-20"
        animate={{
          y: [0, 10, 0],
        }}
        transition={{
          duration: 1.5,
          repeat: Infinity,
          ease: "easeInOut",
        }}
      >
        <FaChevronDown className="text-4xl text-green-500" />
      </motion.div>

      <motion.section 
        className="w-full max-w-4xl"
        initial={{ opacity: 0, y: 50 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.8, delay: 0.3 }}
      >
        <h2 className="text-3xl sm:text-3xl mb-10 text-center text-white mt- font-mono z-10">preorder content</h2>
        <div className="grid grid-cols-1 sm:grid-cols-2 gap-8 z-10">
          <motion.div 
            className="bg-zinc-900 p-6 rounded-lg z-10"
            whileHover={{ scale: 1.05, boxShadow: "0px 0px 8px rgb(16, 185, 129)" }}
            transition={{ type: "spring", stiffness: 300, damping: 10 }}
          >
            <img src="/dev6.jpg" alt="Specialized Hardware" className="w-full h-48 object-cover rounded-lg mb-4" />
            <h3 className="text-xl font-semibold mb-2 text-white font-mono">specialized hardware</h3>
            <p className="mb-2 font-mono">handbuilt fpv configuration for next-gen embodied AI.</p>
            <p className="text-green-500 font-mono">Release Date 2024 Q4</p>
          </motion.div>
          <motion.div 
            className="bg-zinc-900 p-6 rounded-lg z-10"
            whileHover={{ scale: 1.05, boxShadow: "0px 0px 8px rgb(16, 185, 129)" }}
            transition={{ type: "spring", stiffness: 300, damping: 10 }}
          >
            <video className="w-full h-48 object-cover rounded-lg mb-4" autoPlay loop muted playsInline>
              <source src="/dev4.mp4" type="video/mp4" />
              Your browser does not support the video tag.
            </video>
            <h3 className="text-xl font-semibold mb-2 text-white font-mono">6 Months Free Access</h3>
            <p className="mb-2 font-mono">6-month access to colony machine learning sdk upon release.</p>
            <p className="text-green-500 font-mono">Release Date 2025 Q1</p>
          </motion.div>
        </div>
      </motion.section>

      <motion.section 
        className="w-full max-w-4xl"
        initial={{ opacity: 0, y: 50 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.8, delay: 0.6 }}
      >
        <p className="text-lg text-center mb-8 font-mono">DroneForge is bridging the gap between the next generation of AI and traditional FPV drone technology.</p>
        <div className="grid grid-cols-1 sm:grid-cols-3 gap-8">
          {[FaRocket, FaTools, FaPuzzlePiece].map((Icon, index) => (
            <motion.div 
              key={index}
              className="bg-zinc-900 p-6 rounded-lg text-center"
              whileHover={{ scale: 1.05, boxShadow: "0px 0px 8px rgb(16, 185, 129)" }}
              transition={{ type: "spring", stiffness: 300, damping: 10 }}
            >
              <motion.div 
                className="flex justify-center items-center w-16 h-16 bg-zinc-800 rounded-full mx-auto mb-4"
                whileHover={{ rotate: 360 }}
                transition={{ duration: 0.8 }}
              >
                <Icon className="text-2xl text-green-500" />
              </motion.div>
              <h3 className="text-xl font-semibold mb-2 text-white font-mono">{index === 0 ? 'Easy to Start' : index === 1 ? 'Building Services' : 'AI Advancements'}</h3>
              <p className="font-mono">{index === 0 ? 'Get custom built hardware and start flying in no time.' : index === 1 ? 'Expert assistance for assembling custom drones' : 'Innovative advancements for aerial maneuvering and mission planning.'}</p>
            </motion.div>
          ))}
        </div>
      </motion.section>

      <motion.footer 
        className="flex gap-6 flex-wrap items-center justify-center mt-16"
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 0.8, delay: 0.9 }}
      >
        <a
          className="flex text-zinc-100 items-center gap-2 hover:underline hover:underline-offset-4 text-sm sm:text-base"
          href="https://app.termly.io/policy-viewer/policy.html?policyUUID=b0c094bc-be46-4d64-8503-163a23b649da"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img
            aria-hidden
            src="https://nextjs.org/icons/file.svg"
            alt="File icon"
            width={12}
            height={12}
            className="sm:w-4 sm:h-4 "
          />
          Terms of Service
        </a>
        <a
          className="flex text-zinc-100 items-center gap-2 hover:underline hover:underline-offset-4 text-sm sm:text-base"
          href="https://app.termly.io/policy-viewer/policy.html?policyUUID=a5fe3208-c6eb-414b-84e4-947d6e235c99"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img
            aria-hidden
            src="https://nextjs.org/icons/window.svg"
            alt="Window icon"
            width={12}
            height={12}
            className="sm:w-4 sm:h-4"
          />
          Privacy Policy
        </a>
        <a
          className="flex text-zinc-100 items-center gap-2 hover:underline hover:underline-offset-4 text-sm sm:text-base"
          href="https://x.com/chesterzelaya/status/1837639126591721583"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img
            aria-hidden
            src="https://nextjs.org/icons/x.svg"
            alt="Globe icon"
            width={12}
            height={12}
            className="sm:w-4 sm:h-4"
          />
          Twitter
        </a>
        <a
          className="flex text-zinc-100 items-center gap-2 hover:underline hover:underline-offset-4 text-sm sm:text-base"
          href="https://thedroneforge.com/returns.html"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img
            aria-hidden
            src="https://nextjs.org/icons/file.svg"
            alt="Globe icon"
            width={12}
            height={12}
            className="sm:w-4 sm:h-4"
          />
          Returns and Refunds
        </a>
      </motion.footer>

      {/* Waitlist Modal */}
      <AnimatePresence>
        {showWaitlistModal && (
          <motion.div
            className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
          >
            <motion.div
              className="bg-zinc-900 p-8 rounded-lg shadow-lg max-w-md w-full relative"
              initial={{ scale: 0.9, opacity: 0 }}
              animate={{ scale: 1, opacity: 1 }}
              exit={{ scale: 0.9, opacity: 0 }}
            >
              <button
                onClick={closeWaitlistModal}
                className="absolute top-2 right-2 text-gray-500 hover:text-white"
              >
                ✕
              </button>
              <WaitlistForm />
            </motion.div>
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
};

export default Landing_Bryce;