import React, { useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, useLocation } from 'react-router-dom';
import posthog from 'posthog-js';
import Navbar from './components/Navbar';
import Landing_Bryce from './components/Landing_Bryce.tsx';
import SuccessPage from './components/SuccessPage.tsx';
import CancelPage from './components/CancelPage.tsx';
import './App.css';

function AppContent() {
  const location = useLocation();
  const showNavbar = location.pathname !== '/';

  return (
    <>
      {showNavbar && <Navbar />}
      <div className="main-content">
        <Routes>
          <Route path="/" element={<Landing_Bryce />} />
          <Route path="/about" element={<Landing_Bryce />} />
          <Route path="/contact" element={<Landing_Bryce />} />
          <Route path="/success" element={<SuccessPage />} />
          <Route path="/cancel" element={<CancelPage />} />
        </Routes>
      </div>
    </>
  );
}

function App() {
  useEffect(() => {
    const posthogApiKey = process.env.REACT_APP_POSTHOG_API_KEY;
    const posthogHost = process.env.REACT_APP_POSTHOG_HOST;

    if (posthogApiKey && posthogHost) {
      posthog.init(posthogApiKey, {
        api_host: posthogHost
      });
    }
  }, []);

  return (
    <Router>
      <div className="App">
        <AppContent />
      </div>
    </Router>
  );
}

export default App;