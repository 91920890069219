import React from 'react';
import { Link } from 'react-router-dom';

const SuccessPage: React.FC = () => {
  return (
    <div className="success-page">
      <h1>Order Successful!</h1>
      <p>Thank you for your purchase. We'll be in touch with further details soon.</p>
      <Link to="/" className="home-link">Return to Home</Link>
    </div>
  );
};

export default SuccessPage;