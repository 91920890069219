import React from 'react';
import { Link } from 'react-router-dom';

const CancelPage: React.FC = () => {
  return (
    <div className="cancel-page">
      <h1>Order Cancelled</h1>
      <p>Your order has been cancelled. If you have any questions, please contact us.</p>
      <Link to="/" className="home-link">Return to Home</Link>
    </div>
  );
};

export default CancelPage;